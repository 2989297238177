import React from "react";
import theme from "theme";
import { Theme, Text, Strong, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contact"} />
		<Helmet>
			<title>
				STARGAZE EVENTS
			</title>
			<meta name={"description"} content={"Ми створюємо незабутні події, які залишають яскраві враження та дарують радість. Довірте нам свої ідеї, і ми перетворимо їх на реальність."} />
			<meta property={"og:title"} content={"STARGAZE EVENTS"} />
			<meta property={"og:description"} content={"Ми створюємо незабутні події, які залишають яскраві враження та дарують радість. Довірте нам свої ідеї, і ми перетворимо їх на реальність."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/website-example-quarkly.png?v=2020-11-05T19:48:01.806Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65801ab5867c86001f194a8a/images/17_85293.png?v=2023-12-18T10:15:37.785Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65801ab5867c86001f194a8a/images/17_85293.png?v=2023-12-18T10:15:37.785Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65801ab5867c86001f194a8a/images/17_85293.png?v=2023-12-18T10:15:37.785Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65801ab5867c86001f194a8a/images/17_85293.png?v=2023-12-18T10:15:37.785Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65801ab5867c86001f194a8a/images/17_85293.png?v=2023-12-18T10:15:37.785Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65801ab5867c86001f194a8a/images/17_85293.png?v=2023-12-18T10:15:37.785Z"} />
		</Helmet>
		<Components.HeaderNew margin="0px 0px 5px 0px">
			<Override slot="text" />
			<Override slot="quarklycommunityKitMobileSidePanel" />
			<Override slot="text1" />
			<Override slot="image" />
			<Override slot="box3" />
		</Components.HeaderNew>
		<Section padding="100px 0 100px 0" background="#ffffff" quarkly-title="FAQ-9">
			<Text margin="0px 0px 70px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--dark" sm-margin="0px 0px 50px 0px">
				FAQ
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="1fr"
				grid-gap="50px 50px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
				flex-direction="column"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--dark"
						width="40%"
						md-width="100%"
					>
						<Strong>
							1. Як я можу замовити ваші послуги?
						</Strong>
						{"\n"}
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Ви можете зв’язатися з нами через телефон або заповнивши форму на нашому сайті. Ми організуємо консультацію, під час якої обговоримо всі деталі вашого заходу та запропонуємо оптимальні рішення.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--dark"
						width="40%"
						md-width="100%"
					>
						<Strong>
							2. Скільки часу займає підготовка заходу?
						</Strong>
						{"\n"}
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Тривалість підготовки залежить від масштабу та складності заходу. Зазвичай ми рекомендуємо починати планування за кілька місяців до дати проведення. Проте ми готові взятися за організацію навіть у стислі терміни.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--dark"
						width="40%"
						md-width="100%"
					>
						<Strong>
							3. Чи можу я замовити тільки частину послуг?
						</Strong>
						{"\n"}
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Так, ви можете замовити як повний комплекс послуг, так і окремі послуги, які вам необхідні. Ми гнучко підходимо до кожного клієнта та готові запропонувати індивідуальні рішення.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--dark"
						width="40%"
						md-width="100%"
					>
						<Strong>
							4. Які документи необхідні для замовлення послуг?
						</Strong>
						{"\n"}
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Зазвичай для замовлення наших послуг достатньо заповнити договір та внести передоплату. Під час консультації ми обговоримо всі деталі та надамо вам перелік необхідних документів, якщо це потрібно для конкретного заходу.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--dark"
						width="40%"
						md-width="100%"
					>
						<Strong>
							5. Чи надаєте ви послуги за межами міста?
						</Strong>
						{"\n"}
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Так, ми надаємо послуги з організації заходів не тільки в межах міста, але й за його межами. Ми готові виїхати до будь-якої локації, яку ви оберете, та забезпечити проведення заходу на найвищому рівні.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--dark"
						width="40%"
						md-width="100%"
					>
						<Strong>
							6. Яка вартість ваших послуг?
						</Strong>
						{"\n"}
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Вартість наших послуг залежить від типу та масштабів заходу, а також від обсягу робіт, які необхідно виконати. Ми надамо вам детальну комерційну пропозицію після проведення консультації та обговорення всіх деталей вашого заходу.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--dark"
						width="40%"
						md-width="100%"
					>
						<Strong>
							7. Чи можете ви допомогти з вибором локації для заходу?
						</Strong>
						{"\n"}
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Так, ми допоможемо вам обрати оптимальну локацію для вашого заходу, враховуючи всі ваші побажання та вимоги. Ми маємо великий досвід роботи з різними локаціями та знаємо, як забезпечити найкращі умови для проведення вашої події.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--dark"
						width="40%"
						md-width="100%"
					>
						<Strong>
							8. Чи можете ви організувати тематичні заходи?
						</Strong>
						{"\n"}
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Так, ми спеціалізуємося на організації тематичних заходів. Наші креативні фахівці допоможуть розробити концепцію заходу, підібрати декор, костюми, програму та забезпечити неповторну атмосферу відповідно до обраної тематики.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.NewFooter1>
			<Override slot="text" />
		</Components.NewFooter1>
	</Theme>;
});